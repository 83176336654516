<template>
  <div>
    <b-nav card-header pills class="mt-1 ml-2 mb-0">
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="index"
        :to="{ name: tab.route }"
        exact
        exact-active-class="active"
        :link-classes="['sub-tab-nav', 'px-3 h-full', bgTabsNavs]"
      >
        {{ tab.title }}
        <span
          v-if="G_ADDCHANGE_COUNTERS > 0 && tab.title == 'In Process'"
          class="ml-1"
        >
          <b-badge pill variant="danger">
            {{ G_ADDCHANGE_COUNTERS > 99 ? "99+" : G_ADDCHANGE_COUNTERS }}
          </b-badge>
        </span>
      </b-nav-item>
    </b-nav>
    <b-card no-body class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import SalesMadeAddChangeComponent from "@/views/crm/views/sales-made/components/add-change/SalesMadeAddChangeComponent.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ForApprovedComponent",
  components: {
    SalesMadeAddChangeComponent,
  },
  data() {
    return {
      tabs: [
        {
          title: "In Process",
          key: 1,
          done: 0,
          route: this.$route.matched[2].meta.routeForApprovedClientInProcess,
        },
        {
          title: "Completed",
          key: 2,
          done: 1,
          route: this.$route.matched[2].meta.routeForApprovedClientCompleted,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      G_ADDCHANGE_COUNTERS: "CrmSaleMadeStore/G_ADDCHANGE_COUNTERS",
    }),
  },
};
</script>

<style scoped></style>
